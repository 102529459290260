import { NextPage } from "next";
import Head from "next/head";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";
import enMessages from "shared/lang/en.json";

import DefaultErrorPage from "@components/Errors/DefaultErrorPage";

import { pageDataLayer } from "@lib/dataLayers";

const Custom404Page: NextPage = () => {
  const texts = {
    pageTitle: enMessages["ErrorPage.http.404.pageTitle"],
    heading: enMessages["ErrorPage.http.404.heading"],
    description: enMessages["ErrorPage.http.404.description"],
  };
  const { user } = useSelector((state: AppState) => {
    return { user: state.user };
  });

  useEffect(() => {
    pageDataLayer("other", user);
  }, [user]);
  return (
    <>
      <Head>
        <title>Error - 404</title>
      </Head>
      <DefaultErrorPage className="-404" texts={texts} />
    </>
  );
};

export default Custom404Page;
